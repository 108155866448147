import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  SimpleGrid,
  Text
} from "@chakra-ui/react";

import { Response } from "./types";

// Define common styles
const sectionStyle = {
  borderWidth: "0",
  p: 2,
  mb: 2,
  bg: "white"
};

const inputStyle = {
  borderWidth: "0",
  fontSize: "1rem",
  p: 2,
  minH: "1.8rem",
  h: "1.8rem",
  _focus: { bg: "gray.50" }
};

const tableStyle = {
  borderWidth: "0",
  overflow: "hidden",
  sx: {
    "td, th": {
      padding: "0.5rem !important",
      fontSize: "1rem !important",
      borderWidth: "0 !important"
    },
    "th": {
      fontWeight: "600 !important",
      color: "gray.700 !important",
      bg: "gray.50 !important"
    }
  }
};

// Heating System Row Component
const HeatingSystemRow = ({
  label,
  manufacturer,
  model,
  description,
  fuel,
  defaultValue
}: {
  label: string;
  manufacturer?: string;
  model?: string;
  description?: string;
  fuel?: string;
  defaultValue?: string;
}) => (
  <Tr>
    <Td>{label}</Td>

    <Td>
      <Input
        size="sm"
        defaultValue={defaultValue}
        value={manufacturer}
        {...inputStyle}
      />
    </Td>

    <Td>
      <Input
        size="sm"
        defaultValue={defaultValue}
        value={model}
        {...inputStyle}
      />
    </Td>

    <Td>
      <Input
        size="sm"
        value={description}
        {...inputStyle}
      />
    </Td>

    <Td>
      <Input
        size="sm"
        value={fuel}
        {...inputStyle}
      />
    </Td>
  </Tr>
);

// Systems Table Component
const SystemsTable = ({
  heatingSystem1Manufacturer,
  heatingSystem1Model,
  heatingSystem1Description,
  heatingSystem1Fuel,
  heatingSystem2Manufacturer,
  heatingSystem2Model,
  heatingSystem2Description,
  heatingSystem2Fuel,
  secondaryHeatingManufacturer,
  secondaryHeatingModel,
  secondaryHeatingDescription,
  secondaryHeatingFuel,
  hotWaterManufacturer,
  hotWaterModel,
  hotWaterDescription,
  hotWaterFuel
}: {
  heatingSystem1Manufacturer?: string;
  heatingSystem1Model?: string;
  heatingSystem1Description?: string;
  heatingSystem1Fuel?: string;
  heatingSystem2Manufacturer?: string;
  heatingSystem2Model?: string;
  heatingSystem2Description?: string;
  heatingSystem2Fuel?: string;
  secondaryHeatingManufacturer?: string;
  secondaryHeatingModel?: string;
  secondaryHeatingDescription?: string;
  secondaryHeatingFuel?: string;
  hotWaterManufacturer?: string;
  hotWaterModel?: string;
  hotWaterDescription?: string;
  hotWaterFuel?: string;
}) => (
  <Box {...sectionStyle}>
    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr>
          <Th>System</Th>

          <Th>Manufacturer</Th>

          <Th>Model</Th>

          <Th>SAP Description</Th>

          <Th>Fuel</Th>
        </Tr>
      </Thead>

      <Tbody>
        <HeatingSystemRow
          label="Heating system 1"
          manufacturer={heatingSystem1Manufacturer}
          model={heatingSystem1Model}
          description={heatingSystem1Description}
          fuel={heatingSystem1Fuel}
        />

        <HeatingSystemRow
          label="Heating system 2"
          manufacturer={heatingSystem2Manufacturer}
          model={heatingSystem2Model}
          description={heatingSystem2Description}
          fuel={heatingSystem2Fuel}
        />

        <HeatingSystemRow
          label="Secondary heating system"
          manufacturer={secondaryHeatingManufacturer}
          model={secondaryHeatingModel}
          description={secondaryHeatingDescription}
          fuel={secondaryHeatingFuel}
          defaultValue="n/a"
        />

        <HeatingSystemRow
          label="Hot water system"
          manufacturer={hotWaterManufacturer}
          model={hotWaterModel}
          description={hotWaterDescription}
          fuel={hotWaterFuel}
          defaultValue="n/a"
        />
      </Tbody>
    </Table>
  </Box>
);

// System Notes Component
const SystemNotes = ({ notes }: { notes?: string }) => (
  <Box {...sectionStyle}>
    <SimpleGrid
      columns={4}
      spacing={4}
    >
      <Text
        fontWeight="bold"
        p={2}
      >
        Notes
      </Text>

      <Box
        gridColumn="span 3"
        p={2}
      >
        <Input
          size="sm"
          value={notes}
          {...inputStyle}
        />
      </Box>
    </SimpleGrid>
  </Box>
);

// Additional Details Row Component
const AdditionalDetailsRow = ({
  label,
  value,
  notes,
  highlight
}: {
  label: string;
  value?: string;
  notes?: string;
  highlight?: boolean;
}) => (
  <Tr>
    <Td width="33%">{label}</Td>

    <Td bg={highlight ? "purple.100" : undefined}>
      <Input
        size="sm"
        value={value}
        {...inputStyle}
      />
    </Td>

    <Td>
      <Input
        size="sm"
        value={notes}
        {...inputStyle}
      />
    </Td>
  </Tr>
);

// Additional Details Table Component
const AdditionalDetailsTable = ({
  mpanNumber,
  electricityDemand,
  existingCylinderSize,
  cylinderNotes,
  solarPv,
  solarPvNotes,
  existingEvCharger,
  evChargerNotes,
  existingPowerSupply,
  powerSupplyNotes,
  existingMainFuseSize,
  mainFuseNotes,
  spareWays,
  spareWaysNotes,
  compliantElectrics,
  compliantElectricsNotes
}: {
  mpanNumber?: string;
  electricityDemand?: string;
  existingCylinderSize?: string;
  cylinderNotes?: string;
  solarPv?: string;
  solarPvNotes?: string;
  existingEvCharger?: string;
  evChargerNotes?: string;
  existingPowerSupply?: string;
  powerSupplyNotes?: string;
  existingMainFuseSize?: string;
  mainFuseNotes?: string;
  spareWays?: string;
  spareWaysNotes?: string;
  compliantElectrics?: string;
  compliantElectricsNotes?: string;
}) => (
  <Box {...sectionStyle}>
    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Tbody>
        <AdditionalDetailsRow
          label="MPAN #"
          value={mpanNumber}
          highlight
        />

        <AdditionalDetailsRow
          label="Electricity demand"
          value={electricityDemand}
          highlight
        />

        <AdditionalDetailsRow
          label="Cylinder size"
          value={existingCylinderSize}
          notes={cylinderNotes}
        />

        <AdditionalDetailsRow
          label="Solar PV"
          value={solarPv}
          notes={solarPvNotes}
        />

        <AdditionalDetailsRow
          label="EV charger"
          value={existingEvCharger}
          notes={evChargerNotes}
        />

        <AdditionalDetailsRow
          label="Power supply"
          value={existingPowerSupply}
          notes={powerSupplyNotes}
          highlight
        />

        <AdditionalDetailsRow
          label="Main fuse size"
          value={existingMainFuseSize}
          notes={mainFuseNotes}
          highlight
        />

        <AdditionalDetailsRow
          label="Spare ways on distribution board"
          value={spareWays}
          notes={spareWaysNotes}
          highlight
        />

        <AdditionalDetailsRow
          label="Compliant electrics for works?"
          value={compliantElectrics}
          notes={compliantElectricsNotes}
        />
      </Tbody>
    </Table>
  </Box>
);

// Main Component
export function ExistingSystems(props: Response) {
  return (
    <Box
      as="section"
      mb={8}
      p={4}
      bg="gray.50"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="lg"
      boxShadow="sm"
      sx={{ "@media print": { boxShadow: "none" } }}
    >
      <Heading
        as="h2"
        size="lg"
        mb={2}
      >
        Existing Systems
      </Heading>

      <SystemsTable
        heatingSystem1Manufacturer={props.heatingSystem1Manufacturer}
        heatingSystem1Model={props.heatingSystem1Model}
        heatingSystem1Description={props.heatingSystem1Description}
        heatingSystem1Fuel={props.heatingSystem1Fuel}
        heatingSystem2Manufacturer={props.heatingSystem2Manufacturer}
        heatingSystem2Model={props.heatingSystem2Model}
        heatingSystem2Description={props.heatingSystem2Description}
        heatingSystem2Fuel={props.heatingSystem2Fuel}
        secondaryHeatingManufacturer={props.secondaryHeatingManufacturer}
        secondaryHeatingModel={props.secondaryHeatingModel}
        secondaryHeatingDescription={props.secondaryHeatingDescription}
        secondaryHeatingFuel={props.secondaryHeatingFuel}
        hotWaterManufacturer={props.hotWaterManufacturer}
        hotWaterModel={props.hotWaterModel}
        hotWaterDescription={props.hotWaterDescription}
        hotWaterFuel={props.hotWaterFuel}
      />

      <SystemNotes notes={props.systemNotes} />

      <AdditionalDetailsTable
        mpanNumber={props.mpanNumber}
        electricityDemand={props.electricityDemand}
        existingCylinderSize={props.existingCylinderSize}
        cylinderNotes={props.cylinderNotes}
        solarPv={props.solarPv}
        solarPvNotes={props.solarPvNotes}
        existingEvCharger={props.existingEvCharger}
        evChargerNotes={props.evChargerNotes}
        existingPowerSupply={props.existingPowerSupply}
        powerSupplyNotes={props.powerSupplyNotes}
        existingMainFuseSize={props.existingMainFuseSize}
        mainFuseNotes={props.mainFuseNotes}
        spareWays={props.spareWays}
        spareWaysNotes={props.spareWaysNotes}
        compliantElectrics={props.compliantElectrics}
        compliantElectricsNotes={props.compliantElectricsNotes}
      />
    </Box>
  );
}
