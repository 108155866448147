import {
  Box,
  Heading,
  VStack,
  Button,
  HStack,
  Select,
  Text,
  Tooltip,
  IconButton
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import {
  useRef,
  useState
} from "react";
import { useReactToPrint } from "react-to-print";

import { PropertyDetails } from "./PropertyDetails";
import { RetrofitScope } from "./RetrofitScope";
import { ExistingSystems } from "./ExistingSystems";
import SolarDriveway from "./SolarDriveway";
import { ASHPDriveway } from "./ASHPDriveway";
import { Response } from "./types";

// Define common styles for print
const printStyles = (scale: string) => `
  @media print {
    @page {
      size: A3 landscape;
      margin: 8mm;
      margin-header: 0;
      margin-footer: 0;
      marks: none;
    }
    @page section {
      margin: 8mm;
    }
    body {
      background: white !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      width: 100% !important;
      max-width: none !important;
      line-height: 1 !important;
      font-size: calc(${scale} * 85%) !important;
      zoom: ${scale} !important;
      -moz-transform: scale(${scale});
      -moz-transform-origin: 0 0;
    }
    * {
      box-shadow: none !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    input, textarea {
      border: 0.5px solid #999 !important;
      padding: 0.5rem !important;
      min-height: 1.8rem !important;
      height: 1.8rem !important;
      background: #fff !important;
      font-size: 1rem !important;
      color: #000 !important;
    }
    .chakra-checkbox__control {
      border: 1px solid #999 !important;
      height: 1rem !important;
      width: 1rem !important;
    }
    table {
      margin: 0.5rem 0 !important;
      border-collapse: collapse !important;
      page-break-inside: avoid !important;
      border-spacing: 0 !important;
    }
    td, th {
      padding: 0.15rem 0.3rem !important;
      border: 0.5px solid #999 !important;
      vertical-align: middle !important;
      height: auto !important;
      min-height: 0 !important;
      line-height: 1.2 !important;
    }
    th {
      background-color: #f0f0f0 !important;
      font-weight: 600 !important;
      font-size: 0.9em !important;
      padding: 0.2rem 0.3rem !important;
    }
    h2 {
      font-size: 1.1rem !important;
      margin: 0.4rem 0 0.3rem 0 !important;
      font-weight: 700 !important;
      padding: 0 0 0.2rem 0 !important;
      line-height: 1.2 !important;
    }
    h3 {
      font-size: 1rem !important;
      margin: 0.3rem 0 0.2rem 0 !important;
      font-weight: 600 !important;
      color: #333 !important;
      line-height: 1.2 !important;
    }
    h4 {
      font-size: 0.95rem !important;
      margin: 0.2rem 0 !important;
      font-weight: 600 !important;
      line-height: 1.2 !important;
    }
    .chakra-stack {
      gap: 0.3rem !important;
      padding: 0.2rem !important;
    }
    label {
      font-weight: 600 !important;
      color: #333 !important;
      margin: 0.1rem 0 !important;
      font-size: 0.9em !important;
      line-height: 1.2 !important;
    }
    [data-highlighted=true] {
      background-color: #fff3cd !important;
    }
    [data-required=true] {
      background-color: #f8d7da !important;
    }
    .section-box {
      border: 0.5px solid #999 !important;
      margin: 0.3rem 0 !important;
      padding: 0.5rem !important;
    }
    .page-break {
      page-break-before: always !important;
      margin-top: 0 !important;
    }
    .section-content {
      margin: 0 !important;
      padding: 0 !important;
    }
    tr {
      height: auto !important;
      min-height: 0 !important;
      line-height: 1 !important;
    }
    td > *, th > * {
      margin: 0 !important;
      padding: 0 !important;
      line-height: 1 !important;
    }
    section {
      margin: 0 !important;
      padding: 0.5rem !important;
      background: white !important;
    }
    .chakra-container {
      max-width: none !important;
      width: 100% !important;
      padding: 0 !important;
    }
    .chakra-box {
      margin: 0 !important;
      padding: 0.3rem !important;
    }
    .chakra-form-control {
      margin: 0.3rem 0 !important;
      padding: 0.2rem !important;
    }
    .chakra-grid {
      gap: 0.3rem !important;
      row-gap: 0.3rem !important;
      column-gap: 0.3rem !important;
      padding: 0.3rem !important;
    }
  }
`;

export function DrivewayDocument(props: Response) {
  const componentRef = useRef<HTMLDivElement>(null);
  const [ scale, setScale ] = useState("1");
  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  return (
    <>
      <style>{printStyles(scale)}</style>

      {/* Printable content */}
      <Box
        maxWidth="7xl"
        margin="auto"
        padding={2}
      >
        <VStack
          spacing={4}
          align="stretch"
        >
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Heading
              display="inline-block"
              size="xl"
            >
              Driveway Document
            </Heading>

            <HStack spacing={4}>
              <HStack>
                <Text fontSize="sm">Print Scale:</Text>

                <Select
                  size="sm"
                  width="100px"
                  value={scale}
                  onChange={e => setScale(e.target.value)}
                >
                  <option value="0.6">60%</option>

                  <option value="0.7">70%</option>

                  <option value="0.8">80%</option>

                  <option value="0.9">90%</option>

                  <option value="1">100%</option>
                </Select>

                <Tooltip label="Adjust this value if the printout is too large or small in Chrome">
                  <IconButton
                    aria-label="Print scale info"
                    icon={<InfoIcon />}
                    size="sm"
                    variant="ghost"
                  />
                </Tooltip>
              </HStack>

              <Button
                onClick={handlePrint}
                colorScheme="blue"
                size="sm"
              >
                Print Document
              </Button>
            </HStack>
          </Box>

          <Box
            ref={componentRef}
            borderRadius="lg"
            boxShadow="sm"
            className="section-content"
            sx={{
              "@media print": {
                "boxShadow": "none",
                "padding": "0.5rem",
                "& > *": {
                  marginBottom: "1rem",
                  padding: "0.5rem",
                  borderWidth: "1px",
                  borderColor: "#eee",
                  borderRadius: "4px",
                  backgroundColor: "#fff"
                }
              }
            }}
          >
            <Box>
              <PropertyDetails {...props} />
            </Box>

            <Box>
              <RetrofitScope {...props} />
            </Box>

            <Box>
              <ExistingSystems {...props} />
            </Box>

            <Box>
              <SolarDriveway {...props} />
            </Box>

            <Box>
              <ASHPDriveway {...props} />
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
