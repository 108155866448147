import {
  Box,
  Heading,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Textarea,
  Text
} from "@chakra-ui/react";

import { Response } from "./types";

// Define common styles
const sectionStyle = {
  borderWidth: "0",
  p: 2,
  mb: 2,
  bg: "white"
};

const inputStyle = {
  borderWidth: "0",
  fontSize: "1rem",
  p: 2,
  minH: "1.8rem",
  h: "1.8rem",
  _focus: { bg: "gray.50" }
};

const tableStyle = {
  borderWidth: "0",
  overflow: "hidden",
  sx: {
    "td, th": {
      padding: "0.5rem !important",
      fontSize: "1rem !important",
      borderWidth: "0 !important"
    },
    "th": {
      fontWeight: "600 !important",
      color: "gray.700 !important",
      bg: "gray.50 !important"
    }
  }
};

interface StakeholderFields {
  name: string;
  phone: string;
  details: string;
}

// Basic Property Details Component
const BasicDetails = ({
  epcNumber,
  offstreetParking,
  sapGrade,
  wasteDisposalRequired
}: {
  epcNumber?: string;
  offstreetParking?: boolean;
  sapGrade?: string;
  wasteDisposalRequired?: boolean;
}) => (
  <Box {...sectionStyle}>
    <SimpleGrid
      columns={2}
      spacing={4}
    >
      <FormControl>
        <FormLabel>EPC number</FormLabel>

        <Input
          name="epcNumber"
          value={epcNumber}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Parking</FormLabel>

        <Checkbox
          name="offstreetParking"
          isChecked={offstreetParking}
        />
      </FormControl>

      <FormControl>
        <FormLabel>EPC grade</FormLabel>

        <Input
          name="sapGrade"
          value={sapGrade}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Waste disposal required</FormLabel>

        <Checkbox
          name="wasteDisposalRequired"
          isChecked={wasteDisposalRequired}
        />
      </FormControl>
    </SimpleGrid>
  </Box>
);

// Address Component
const AddressDetails = ({
  addressLine1,
  addressLine2,
  city,
  postcode
}: {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
}) => (
  <Box {...sectionStyle}>
    <FormControl>
      <FormLabel>Property address</FormLabel>

      <SimpleGrid
        columns={4}
        spacing={2}
      >
        <Input
          name="addressLine1"
          value={addressLine1}
          {...inputStyle}
        />

        <Input
          name="addressLine2"
          value={addressLine2}
          {...inputStyle}
        />

        <Input
          name="city"
          value={city}
          {...inputStyle}
        />

        <Input
          name="postcode"
          value={postcode}
          {...inputStyle}
        />
      </SimpleGrid>
    </FormControl>
  </Box>
);

// Property Characteristics Component
const PropertyCharacteristics = ({
  detachment,
  houseType,
  propertyAge,
  numBedrooms,
  floorArea,
  conservationArea,
  frontOfHouseImage,
  floorplanImage
}: {
  detachment?: string;
  houseType?: string;
  propertyAge?: string;
  numBedrooms?: string;
  floorArea?: string;
  conservationArea?: string;
  frontOfHouseImage?: string;
  floorplanImage?: string;
}) => (
  <Box {...sectionStyle}>
    <SimpleGrid
      columns={2}
      spacing={4}
    >
      <FormControl>
        <FormLabel>Attachment</FormLabel>

        <Input
          name="detachment"
          value={detachment}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Built form</FormLabel>

        <Input
          name="houseType"
          value={houseType}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Main building age</FormLabel>

        <Input
          name="propertyAge"
          value={propertyAge}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel># Bedrooms</FormLabel>

        <Input
          name="numBedrooms"
          type="number"
          value={numBedrooms}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Total floor area</FormLabel>

        <Input
          name="floorArea"
          type="number"
          value={floorArea}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Conservation status</FormLabel>

        <Input
          name="conservationArea"
          value={conservationArea}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Front of house</FormLabel>

        {frontOfHouseImage && (
          <Box mt={2}>
            <img
              src={frontOfHouseImage}
              alt="Front of house"
              style={{
                maxWidth: "600px",
                maxHeight: "450px",
                width: "auto",
                height: "auto",
                objectFit: "contain"
              }}
            />
          </Box>
        )}
      </FormControl>

      <FormControl>
        <FormLabel>Floorplan</FormLabel>

        {floorplanImage && (
          <Box mt={2}>
            <img
              src={floorplanImage}
              alt="Floorplan"
              style={{
                maxWidth: "600px",
                maxHeight: "450px",
                width: "auto",
                height: "auto",
                objectFit: "contain"
              }}
            />
          </Box>
        )}
      </FormControl>
    </SimpleGrid>
  </Box>
);

// Customer Details Component
const CustomerDetails = ({
  customerName,
  customerPhone,
  customerDetails
}: {
  customerName?: string;
  customerPhone?: string;
  customerDetails?: string;
}) => (
  <Box {...sectionStyle}>
    <Heading
      as="h4"
      size="sm"
      mb={2}
    >
      Customer
    </Heading>

    <SimpleGrid
      columns={3}
      spacing={4}
    >
      <FormControl>
        <FormLabel>Name</FormLabel>

        <Input
          name="customerName"
          value={customerName}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Phone</FormLabel>

        <Input
          name="customerPhone"
          type="tel"
          value={customerPhone}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Details</FormLabel>

        <Input
          name="customerDetails"
          value={customerDetails}
          {...inputStyle}
        />
      </FormControl>
    </SimpleGrid>
  </Box>
);

// Stakeholder Details Component
const StakeholderDetails = ({
  num,
  name,
  phone,
  details
}: {
  num: number;
  name?: string;
  phone?: string;
  details?: string;
}) => (
  <Box {...sectionStyle}>
    <Heading
      as="h4"
      size="sm"
      mb={2}
    >
      Stakeholder {num}
    </Heading>

    <SimpleGrid
      columns={3}
      spacing={4}
    >
      <FormControl>
        <FormLabel>Name</FormLabel>

        <Input
          name={`stakeholder${num}Name`}
          value={name}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Phone</FormLabel>

        <Input
          name={`stakeholder${num}Phone`}
          type="tel"
          value={phone}
          {...inputStyle}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Details</FormLabel>

        <Input
          name={`stakeholder${num}Details`}
          value={details}
          {...inputStyle}
        />
      </FormControl>
    </SimpleGrid>
  </Box>
);

// Additional Information Component
const AdditionalInformation = ({
  customerAims,
  customerInformationForInstaller,
  propertyAccessChallenges
}: {
  customerAims?: string;
  customerInformationForInstaller?: string;
  propertyAccessChallenges?: string;
}) => (
  <>
    <Box {...sectionStyle}>
      <SimpleGrid
        columns={2}
        spacing={6}
      >
        <FormControl>
          <FormLabel>Customer aims</FormLabel>

          <Input
            value={customerAims}
            {...inputStyle}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Customer information for installer</FormLabel>

          <Input
            value={customerInformationForInstaller}
            {...inputStyle}
          />
        </FormControl>
      </SimpleGrid>
    </Box>

    <FormControl
      mt={6}
      {...sectionStyle}
    >
      <FormLabel>Property access challenges</FormLabel>

      <Textarea
        name="propertyAccessChallenges"
        rows={3}
        value={propertyAccessChallenges}
        {...inputStyle}
      />
    </FormControl>
  </>
);

// Main Component
export function PropertyDetails({
  epcNumber,
  offstreetParking,
  sapGrade,
  wasteDisposalRequired,
  addressLine1,
  addressLine2,
  city,
  postcode,
  detachment,
  houseType,
  propertyAge,
  numBedrooms,
  floorArea,
  conservationArea,
  frontOfHouseImage,
  floorplanImage,
  customerName,
  customerPhone,
  customerDetails,
  customerAims,
  customerInformationForInstaller,
  propertyAccessChallenges,
  stakeholder2Name,
  stakeholder2Phone,
  stakeholder2Details,
  stakeholder3Name,
  stakeholder3Phone,
  stakeholder3Details,
  stakeholder4Name,
  stakeholder4Phone,
  stakeholder4Details
}: Response) {
  const getStakeholderFields = (num: number): StakeholderFields => {
    switch (num) {
      case 2:
        return {
          name: stakeholder2Name || "",
          phone: stakeholder2Phone || "",
          details: stakeholder2Details || ""
        };
      case 3:
        return {
          name: stakeholder3Name || "",
          phone: stakeholder3Phone || "",
          details: stakeholder3Details || ""
        };
      case 4:
        return {
          name: stakeholder4Name || "",
          phone: stakeholder4Phone || "",
          details: stakeholder4Details || ""
        };
      default:
        return {
          name: "",
          phone: "",
          details: ""
        };
    }
  };

  return (
    <Box
      as="section"
      mb={8}
      p={4}
      bg="gray.50"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="lg"
      boxShadow="sm"
      sx={{ "@media print": { boxShadow: "none" } }}
    >
      <Heading
        as="h2"
        size="lg"
        mb={2}
      >
        Property/Client Details
      </Heading>

      <VStack
        spacing={3}
        align="stretch"
      >
        <BasicDetails
          epcNumber={epcNumber}
          offstreetParking={offstreetParking}
          sapGrade={sapGrade}
          wasteDisposalRequired={wasteDisposalRequired}
        />

        <AddressDetails
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          postcode={postcode}
        />

        <PropertyCharacteristics
          detachment={detachment}
          houseType={houseType}
          propertyAge={propertyAge}
          numBedrooms={numBedrooms}
          floorArea={floorArea}
          conservationArea={conservationArea}
          frontOfHouseImage={frontOfHouseImage}
          floorplanImage={floorplanImage}
        />

        <Heading
          as="h3"
          size="md"
          mb={4}
        >
          Customer/Stakeholder Information
        </Heading>

        <VStack
          spacing={6}
          align="stretch"
        >
          <CustomerDetails
            customerName={customerName}
            customerPhone={customerPhone}
            customerDetails={customerDetails}
          />

          {[
            2,
            3,
            4
          ].map(num => {
            const fields = getStakeholderFields(num);

            return (
              <StakeholderDetails
                key={num}
                num={num}
                name={fields.name}
                phone={fields.phone}
                details={fields.details}
              />
            );
          })}
        </VStack>

        <AdditionalInformation
          customerAims={customerAims}
          customerInformationForInstaller={customerInformationForInstaller}
          propertyAccessChallenges={propertyAccessChallenges}
        />
      </VStack>
    </Box>
  );
}
