import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Textarea,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Text,
  Flex
} from "@chakra-ui/react";

import { Response } from "./types";

// Define common styles
const sectionStyle = {
  borderWidth: "0",
  p: 2,
  mb: 2,
  bg: "white"
};

const inputStyle = {
  borderWidth: "0",
  fontSize: "1rem",
  p: 2,
  minH: "1.8rem",
  h: "1.8rem",
  _focus: { bg: "gray.50" }
};

const tableStyle = {
  borderWidth: "0",
  overflow: "hidden",
  sx: {
    "td, th": {
      padding: "0.5rem !important",
      fontSize: "1rem !important",
      borderWidth: "0 !important"
    },
    "th": {
      fontWeight: "600 !important",
      color: "gray.700 !important",
      bg: "gray.50 !important"
    }
  }
};

// Define the props type
type ASHPDrivewayProps = {
  // ASHP Installer Details
  ashpInstallerName?: string;
  ashpInstallerEmail?: string;
  ashpInstallerTelephone?: string;
  ashpInstallerContact?: string;

  // Job Overview
  pointOfConnection?: string;
  newWetSystem?: string;
  plumbingUpgrade?: string;
  pressurisedVented?: string;

  // New Equipment
  heatPumpDetails?: string;
  heatPumpNumber?: string;
  heatPumpNumberModel2?: string;
  heatPumpModel2Details?: string;
  cylinderDetails?: string;
  cylinderNumber?: string;
  cylinderModel2Details?: string;
  cylinderModel2Number?: string;
  bufferNumber?: string;
  bufferDetails?: string;
  bufferModel2Details?: string;
  rauvithermDetails?: string;
  rauvithermNumber?: string;
  consumables?: string;
  equipmentNotes?: string;

  // Arrangement
  schematic?: string;
  numberOfZones?: string;
  futureHeatingControls?: string;
  existingControlsIntegration?: string;
  treatmentOfTrvs?: string;
  flowTemperature?: string;
  primaryPipeSize?: string;
  installType?: string;
  heatingNeedsCovered?: string;
  pureHybridSystem?: string;
  installComplexity?: string;

  // Proposed Layout
  externalHeatPumpLocation?: string;
  cylinderLocation?: string;
  bufferLocation?: string;
  extUnitCylinderDistance?: string;
  extUnitCylinderNotes?: string;
  nonPrimaryPipeworkLength?: string;
  nonPrimaryPipeworkNotes?: string;

  // ASHP Commissioning Settings
  lowTempAmbient?: string;
  waterTempLowAmbient?: string;
  highTempAmbient?: string;
  waterTempHighAmbient?: string;
  hotWaterSetTemp?: string;
  legionellaTime?: string;

  // Additional Notes
  ashpNotes?: string;

  // Additional Issues
  pumpOnRoof?: boolean;
  pumpOnRoofComment?: string;
  pumpOffGround?: boolean;
  pumpOffGroundComment?: string;
  cylinderLocationWorks?: boolean;
  cylinderLocationWorksComment?: string;
  plinth?: boolean;
  plinthComment?: string;
  otherCivils?: boolean;
  otherCivilsComment?: string;
  electricalBoardUpgrade?: boolean;
  removeShowerPumps?: boolean;
  upgradeShowers?: boolean;
  singlePipeUpgrade?: boolean;
  otherIssues?: boolean;

  // Emitter Upgrades
  emitters?: Array<{name: string; existingEmitterNum: string; newEmitterNum: string; reuseEmitterNum: string; newEmitterSpec: string; installerCorrectionNotes: string;}>;
  emitterRemit?: string;
  genousNotes?: string;
  ufhRole?: string;

  // UFH Specification
  ufhSystem1?: {
    storeys?: string;
    manifolds?: string;
    zones?: string;
    type?: string;
    squareMeters?: string;
    additionalEquipment?: string;
    notes?: string;
  };
  ufhSystem2?: {
    storeys?: string;
    manifolds?: string;
    zones?: string;
    type?: string;
    squareMeters?: string;
    additionalEquipment?: string;
    notes?: string;
  };
  ufhSystem3?: {
    storeys?: string;
    manifolds?: string;
    zones?: string;
    type?: string;
    squareMeters?: string;
    additionalEquipment?: string;
    notes?: string;
  };

  // Noise Assessment
  heatPumpDistance?: string;
  surfacesCount?: string;
  shieldingAssessment?: string;
  noiseAssessmentNotes?: string;

  // Existing System Quality
  heatingSystemLeaking?: boolean;
  heatingSystemLeakingComment?: string;
  heatingSystemWorking?: boolean;
  heatingSystemWorkingComment?: string;
  electricsGoodRepair?: boolean;
  electricsGoodRepairNotes?: string;
  heatingSystemQuality?: string;
  heatingSystemQualityNotes?: string;

  // Job Summary Commentary
  projectDeliverabilityIssues?: string;
  projectPricingIssues?: string;
  outstandingDesignIssues?: string;
  // images
  ashpSchematic?: string;
  ashpPlans?: string;

  jobSummaryCommentaryPhotoOne?: string;
  jobSummaryCommentaryPhotoTwo?: string;
  totalPumpCapac?: string;
  ashpTotalHeatLoss?: string;
};

// Helper function to handle boolean display with minimal components
function BooleanField(props: { value: boolean | undefined }) {
  return (
    <Text>{props.value === true ? "Yes" : "No"}</Text>
  );
}

// Use a more specific type to avoid complex union type error
type ASHPProps = Partial<Response>;

export function ASHPDriveway(props: ASHPProps) {
  return (
    <Box
      as="section"
      mb={8}
      p={4}
      bg="gray.50"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="lg"
      boxShadow="sm"
      sx={{ "@media print": { boxShadow: "none" } }}
    >
      <Heading
        as="h2"
        size="lg"
        mb={4}
      >
        ASHP Driveway
      </Heading>

      {/* ASHP Installer Details */}
      <Box {...sectionStyle}>
        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Thead>
            <Tr bg="gray.100">
              <Th>ASHP installer</Th>

              <Th>Email</Th>

              <Th>Telephone</Th>

              <Th>Contact</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>
                <Input
                  name="ashpInstallerName"
                  size="sm"
                  value={props.ashpInstallerName}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  name="ashpInstallerEmail"
                  type="email"
                  size="sm"
                  value={props.ashpInstallerEmail}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  name="ashpInstallerTelephone"
                  type="tel"
                  size="sm"
                  value={props.ashpInstallerTelephone}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  name="ashpInstallerContact"
                  size="sm"
                  value={props.ashpInstallerContact}
                  {...inputStyle}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Job Overview */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          Job Overview
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Tbody>
            <Tr>
              <Td width="33%">Point of connection?</Td>

              <Td><Input
                name="pointOfConnection"
                size="sm"
                value={props.pointOfConnection}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>New wet system?</Td>

              <Td><Input
                name="newWetSystem"
                size="sm"
                value={props.newWetSystem}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Plumbing upgrade/remediation?</Td>

              <Td><Input
                name="plumbingUpgrade"
                size="sm"
                value={props.plumbingUpgrade}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Pressurised/vented?</Td>

              <Td><Input
                name="pressurisedVented"
                size="sm"
                value={props.pressurisedVented}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Total heat loss (kW)?</Td>

              <Td><Input
                name="ashpTotalHeatLoss"
                size="sm"
                value={props.ashpTotalHeatLoss}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Total pump capacity at design temps (kW)?</Td>

              <Td><Input
                name="totalPumpCapac"
                size="sm"
                value={props.totalPumpCapac}
                {...inputStyle}
                  />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* New Equipment */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          New Equipment
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Thead>
            <Tr bg="gray.100">
              <Th>New equipment</Th>

              <Th>Details</Th>

              <Th># or m</Th>

              <Th>Details</Th>

              <Th># or m</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Heat pump (ext. unit)</Td>

              <Td bg="yellow.50"><Input
                size="sm"
                value={props.heatPumpDetails}
                {...inputStyle}
                                 />
              </Td>

              <Td><Input
                size="sm"
                value={props.heatPumpNumber}
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Cylinder</Td>

              <Td><Input
                size="sm"
                value={props.cylinderDetails}
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                value={props.cylinderNumber}
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Buffer/low loss header</Td>

              <Td><Input
                size="sm"
                value={props.bufferDetails}
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Rauvitherm</Td>

              <Td bg="purple.50"><Input
                size="sm"
                value={props.rauvithermDetails}
                {...inputStyle}
                                 />
              </Td>

              <Td bg="purple.50"><Input
                size="sm"
                value={props.rauvithermNumber}
                {...inputStyle}
                                 />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Consumables</Td>

              <Td
                colSpan={2}
                bg="purple.50"
              >
                <Input
                  size="sm"
                  value={props.consumables}
                  {...inputStyle}
                />
              </Td>

              <Td colSpan={2}>
                <Input
                  size="sm"
                  {...inputStyle}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>Notes</Td>

              <Td
                colSpan={4}
              >
                <Input
                  size="sm"
                  value={props.equipmentNotes}
                  {...inputStyle}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Arrangement */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          Arrangement
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Tbody>
            <Tr>
              <Td>Schematic</Td>

              <Td><Input
                size="sm"
                value={props.schematic}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Number of zones</Td>

              <Td><Input
                size="sm"
                value={props.numberOfZones}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Future heating controls</Td>

              <Td><Input
                size="sm"
                value={props.futureHeatingControls}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Existing controls integration</Td>

              <Td><Input
                size="sm"
                value={props.existingControlsIntegration}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Treatment of TRVs</Td>

              <Td><Input
                size="sm"
                value={props.treatmentOfTrvs}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Flow temperature</Td>

              <Td><Input
                size="sm"
                value={props.flowTemperature}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Primary pipe-size</Td>

              <Td><Input
                size="sm"
                value={props.primaryPipeSize}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Install type</Td>

              <Td><Input
                size="sm"
                value={props.installType}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Heating needs covered</Td>

              <Td><Input
                size="sm"
                value={props.heatingNeedsCovered}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Pure/hybrid system</Td>

              <Td><Input
                size="sm"
                value={props.pureHybridSystem}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Install complexity</Td>

              <Td bg="purple.50"><Input
                size="sm"
                value={props.installComplexity}
                {...inputStyle}
                                 />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Proposed Layout */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          Proposed Layout
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Tbody>
            <Tr>
              <Td>External heat pump unit location</Td>

              <Td bg="purple.50"><Input
                size="sm"
                value={props.externalHeatPumpLocation}
                {...inputStyle}
                                 />
              </Td>
            </Tr>

            <Tr>
              <Td>Cylinder location</Td>

              <Td><Input
                size="sm"
                value={props.cylinderLocation}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Buffer/other system components location</Td>

              <Td><Input
                size="sm"
                value={props.bufferLocation}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>
                Ext. unit-cylinder proposed distance
                <br />
                (ex Rauvitherm)
              </Td>

              <Td>
                <SimpleGrid
                  columns={2}
                  spacing={2}
                >
                  <Input
                    size="sm"
                    bg="purple.50"
                    value={props.extUnitCylinderDistance}
                    {...inputStyle}
                  />

                  <Input
                    size="sm"
                    value={props.extUnitCylinderNotes}
                    {...inputStyle}
                  />
                </SimpleGrid>
              </Td>
            </Tr>

            <Tr>
              <Td>Non-primary pipework length required</Td>

              <Td>
                <SimpleGrid
                  columns={2}
                  spacing={2}
                >
                  <Input
                    size="sm"
                    value={props.nonPrimaryPipeworkLength}
                    {...inputStyle}
                  />

                  <Input
                    size="sm"
                    value={props.nonPrimaryPipeworkNotes}
                    {...inputStyle}
                  />
                </SimpleGrid>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* ASHP Commissioning Settings */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          ASHP Commissioning Settings
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Tbody>
            <Tr>
              <Td>Low temp ambient (air design temp)</Td>

              <Td><Input
                size="sm"
                value={props.lowTempAmbient}
                {...inputStyle}
                  />
              </Td>

              <Td>Water temp at low ambient</Td>

              <Td><Input
                size="sm"
                value={props.waterTempLowAmbient}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>High-temp ambient</Td>

              <Td><Input
                size="sm"
                value={props.highTempAmbient}
                {...inputStyle}
                  />
              </Td>

              <Td>Water temp at high ambient</Td>

              <Td><Input
                size="sm"
                value={props.waterTempHighAmbient}
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Hot water set temp</Td>

              <Td><Input
                size="sm"
                value={props.hotWaterSetTemp}
                {...inputStyle}
                  />
              </Td>

              <Td>Legionella time</Td>

              <Td><Input
                size="sm"
                value={props.legionellaTime}
                {...inputStyle}
                  />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Additional Notes */}
      <Box
        {...sectionStyle}
        mt={6}
      >
        <FormControl>
          <FormLabel>Notes</FormLabel>

          <Textarea
            name="ashpNotes"
            rows={3}
            value={props.ashpNotes}
            {...inputStyle}
          />
        </FormControl>
      </Box>

      {/* Additional Issues */}
      <Box
        {...sectionStyle}
        mb={6}
      >
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          Additional Issues
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Thead>
            <Tr bg="gray.100">
              <Th>Additional issues</Th>

              <Th>Needed?</Th>

              <Th>Comment/who is doing</Th>

              <Th>Issue</Th>

              <Th>Needed?</Th>

              <Th>Comment/who is doing</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Pump on roof</Td>

              <Td>
                <BooleanField
                  value={props.pumpOnRoof}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={props.pumpOnRoofComment}
                  {...inputStyle}
                />
              </Td>

              <Td>Electrical board upgrade</Td>

              <Td>
                <BooleanField
                  value={props.electricalBoardUpgrade}
                />
              </Td>

              <Td><Input
                size="sm"
                {...inputStyle}
                  />
              </Td>
            </Tr>

            <Tr>
              <Td>Pump off ground</Td>

              <Td bg="purple.50">
                <BooleanField
                  value={props.pumpOffGround}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={props.pumpOffGroundComment}
                  {...inputStyle}
                />
              </Td>

              <Td>Remove shower pumps</Td>

              <Td>
                <BooleanField
                  value={props.removeShowerPumps}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>Cylinder location works</Td>

              <Td bg="purple.50">
                <BooleanField
                  value={props.cylinderLocationWorks}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={props.cylinderLocationWorksComment}
                  {...inputStyle}
                />
              </Td>

              <Td>Upgrade showers if pressure</Td>

              <Td>
                <BooleanField
                  value={props.upgradeShowers}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>Plinth</Td>

              <Td bg="purple.50">
                <BooleanField
                  value={props.plinth}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={props.plinthComment}
                  {...inputStyle}
                />
              </Td>

              <Td>Single pipe upgrade</Td>

              <Td>
                <BooleanField
                  value={props.singlePipeUpgrade}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>Other civils needed</Td>

              <Td bg="purple.50">
                <BooleanField
                  value={props.otherCivils}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={props.otherCivilsComment}
                  {...inputStyle}
                />
              </Td>

              <Td>Other</Td>

              <Td>
                <BooleanField
                  value={props.otherIssues}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Emitter Upgrades */}
      <Box {...sectionStyle}>
        <Heading
          as="h3"
          size="md"
          mb={2}
        >
          Emitter Upgrades
        </Heading>

        <Table
          variant="simple"
          size="sm"
          {...tableStyle}
        >
          <Thead>
            <Tr bg="gray.100">
              <Th>Emitter upgrades</Th>

              <Th>Existing emitter #</Th>

              <Th>New emitter #</Th>

              <Th>Reuse old emitter #</Th>

              <Th>New emitter spec</Th>

              <Th>Installer corrections/notes</Th>
            </Tr>
          </Thead>

          <Tbody>
            {props.emitters?.map(emitter => (
              <Tr key={`${emitter.name}-${emitter.existingEmitterNum}`}>
                <Td bg="yellow.50">
                  <Input
                    size="sm"
                    value={emitter.name}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={emitter.existingEmitterNum}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={emitter.newEmitterNum}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={emitter.reuseEmitterNum}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <VStack
                    align="start"
                    spacing={1}
                  >
                    {emitter.newEmitterSpec?.map(spec => (
                      <Text
                        key={`${emitter.name}-${spec}`}
                        fontSize="sm"
                      >{spec}
                      </Text>
                    ))}
                  </VStack>
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={emitter.installerCorrectionNotes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <VStack
          spacing={4}
          mt={4}
        >
          <SimpleGrid
            columns={2}
            spacing={4}
            width="100%"
          >
            <FormControl>
              <FormLabel>Emitter remit</FormLabel>

              <Input
                value={props.emitterRemit}
                {...inputStyle}
              />
            </FormControl>
          </SimpleGrid>

          <FormControl>
            <FormLabel>Genous notes</FormLabel>

            <Textarea
              rows={3}
              value={props.genousNotes}
              {...inputStyle}
            />
          </FormControl>
        </VStack>

        {/* UFH Requirements */}
        <Box
          {...sectionStyle}
          mt={4}
        >
          <Heading
            as="h4"
            size="md"
            mb={2}
          >
            UFH requirements (if needed)
          </Heading>

          <SimpleGrid
            columns={2}
            spacing={4}
          >
            <FormControl>
              <FormLabel>UFH role</FormLabel>

              <Input
                value={props.ufhRole}
                {...inputStyle}
              />
            </FormControl>
          </SimpleGrid>
        </Box>

        {/* UFH Specification */}
        <Box
          {...sectionStyle}
          mb={6}
        >
          <Heading
            as="h3"
            size="md"
            mb={2}
          >
            UFH Specification (if used)
          </Heading>

          <Table
            variant="simple"
            size="sm"
            {...tableStyle}
          >
            <Thead>
              <Tr bg="gray.100">
                <Th>System</Th>

                <Th>Storeys</Th>

                <Th># manifolds</Th>

                <Th># zones/circuits</Th>

                <Th>Type</Th>

                <Th>Square meters required</Th>

                <Th>Additional equipment</Th>

                <Th>Notes</Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td>UFH system #1</Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.storeys}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.manifolds}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.zones}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.type}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.squareMeters}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.additionalEquipment}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem1?.notes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>UFH system #2</Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.storeys}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.manifolds}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.zones}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.type}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.squareMeters}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.additionalEquipment}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem2?.notes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>UFH system #3</Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.storeys}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.manifolds}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.zones}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.type}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.squareMeters}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.additionalEquipment}
                    {...inputStyle}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.ufhSystem3?.notes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        {/* Noise Assessment */}
        <Box
          {...sectionStyle}
          mb={6}
        >
          <Heading
            as="h3"
            size="md"
            mb={2}
          >
            Noise Assessment
          </Heading>

          <Table
            variant="simple"
            size="sm"
            {...tableStyle}
          >
            <Tbody>
              <Tr>
                <Td>
                  Heat pump distance to nearest
                  <br />
                  neighbour window/door (assessment

                  <br />
                  point), m
                </Td>

                <Td bg="purple.50"><Input
                  size="sm"
                  value={props.heatPumpDistance}
                  {...inputStyle}
                                   />
                </Td>

                <Td
                  rowSpan={3}
                >
                  <Textarea
                    height="100%"
                    value={props.noiseAssessmentNotes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>
                  No. of surfaces (walls/floor/roof) {"<"}1m

                  <br />
                  of unit
                </Td>

                <Td bg="purple.50"><Input
                  size="sm"
                  value={props.surfacesCount}
                  {...inputStyle}
                                   />
                </Td>
              </Tr>

              <Tr>
                <Td>
                  Shielding of heat pump to assessment
                  <br />
                  point
                </Td>

                <Td bg="purple.50"><Input
                  size="sm"
                  value={props.shieldingAssessment}
                  {...inputStyle}
                                   />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        {/* Existing System Quality */}
        <Box
          {...sectionStyle}
          mb={6}
        >
          <Heading
            as="h3"
            size="md"
            mb={2}
          >
            Existing System Quality
          </Heading>

          <Table
            variant="simple"
            size="sm"
            {...tableStyle}
          >
            <Tbody>
              <Tr>
                <Td>Is heating system leaking?</Td>

                <Td bg="purple.50">
                  <BooleanField
                    value={props.heatingSystemLeaking}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.heatingSystemLeakingComment}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>Is heating system currently working?</Td>

                <Td bg="purple.50">
                  <BooleanField
                    value={props.heatingSystemWorking}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.heatingSystemWorkingComment}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>Are electrics in good state of repair?</Td>

                <Td bg="purple.50">
                  <BooleanField
                    value={props.electricsGoodRepair}
                  />
                </Td>

                <Td>
                  <Input
                    size="sm"
                    value={props.electricsGoodRepairNotes}
                    {...inputStyle}
                  />
                </Td>
              </Tr>

              <Tr>
                <Td>Heating system quality</Td>

                <Td bg="purple.50"><Input
                  size="sm"
                  {...inputStyle}
                                   />
                </Td>

                <Td><Input
                  size="sm"
                  {...inputStyle}
                    />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        {/* Schematics and Plans */}
        <SimpleGrid
          columns={2}
          spacing={6}
          mb={6}
        >
          <Box {...sectionStyle}>
            <Heading
              as="h3"
              size="md"
              mb={2}
            >
              ASHP Schematic
            </Heading>

            <Box
              bg="blue.50"
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              width="100%"
              position="relative"
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FormLabel
                htmlFor="ashp-schematic"
                cursor="pointer"
                textAlign="center"
              >
                {props.ashpSchematic && (
                  <Box mt={2}>
                    <img
                      src={props.ashpSchematic}
                      alt="ASHP Schematic"
                      style={{
                        maxWidth: "600px",
                        maxHeight: "450px",
                        width: "auto",
                        height: "auto",
                        objectFit: "contain"
                      }}
                    />
                  </Box>
                )}
              </FormLabel>
            </Box>

            <Box
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="md"
              p={2}
            >
              <Textarea
                placeholder="Notes"
                rows={2}
                border="none"
                resize="none"
                {...inputStyle}
              />
            </Box>
          </Box>

          <Box {...sectionStyle}>
            <Heading
              as="h3"
              size="md"
              mb={2}
            >
              Plans
            </Heading>

            <Box
              bg="blue.50"
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              width="100%"
              position="relative"
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FormLabel
                htmlFor="plans-jpg"
                cursor="pointer"
                textAlign="center"
              >
                {props.ashpPlans && (
                  <Box mt={2}>
                    <img
                      src={props.ashpPlans}
                      alt="ASHP Plans"
                      style={{
                        maxWidth: "600px",
                        maxHeight: "450px",
                        width: "auto",
                        height: "auto",
                        objectFit: "contain"
                      }}
                    />
                  </Box>
                )}
              </FormLabel>
            </Box>

            <Box
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="md"
              p={2}
            >
              <Textarea
                placeholder="Notes"
                rows={2}
                border="none"
                resize="none"
                {...inputStyle}
              />
            </Box>
          </Box>
        </SimpleGrid>

        {/* Job Summary Commentary */}
        <Box
          {...sectionStyle}
          mb={6}
        >
          <Heading
            as="h3"
            size="lg"
            mb={4}
          >
            Job Summary Commentary
          </Heading>

          <VStack spacing={4}>
            <FormControl>
              <FormLabel>
                Issues on project deliverability (not mentioned above)
              </FormLabel>

              <Textarea
                rows={3}
                bg="purple.100"
                value={props.projectDeliverabilityIssues}
                {...inputStyle}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                Issues on project pricing (not mentioned above)
              </FormLabel>

              <Textarea
                rows={3}
                bg="purple.100"
                value={props.projectPricingIssues}
                {...inputStyle}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                Outstanding design issues to be resolved
              </FormLabel>

              <Textarea
                rows={3}
                bg="purple.100"
                value={props.outstandingDesignIssues}
                {...inputStyle}
              />
            </FormControl>
          </VStack>
        </Box>

        {/* Legend */}
        <Flex
          gap={6}
          mb={6}
          fontSize="sm"
        >
          <Flex
            alignItems="center"
            gap={2}
          >
            <Box
              w="150px"
              h={4}
              bg="pink.50"
              borderWidth="1px"
              borderColor="pink.200"
            />

            <Text>To be checked</Text>
          </Flex>

          <Flex
            alignItems="center"
            gap={2}
          >
            <Box
              w="150px"
              h={4}
              bg="purple.700"
            />

            <Text>Must be completed unless not applicable</Text>
          </Flex>

          <Flex
            alignItems="center"
            gap={2}
          >
            <Box
              w="150px"
              h={4}
              borderWidth="1px"
              borderColor="gray.300"
            />

            <Text>For information only</Text>
          </Flex>
        </Flex>

        {/* Additional Photos */}
        <Box
          {...sectionStyle}
          mb={6}
        >
          <Heading
            as="h3"
            size="md"
            mb={2}
          >
            Add Photos
          </Heading>

          <SimpleGrid
            columns={2}
            spacing={6}
          >
            <Box
              bg="blue.50"
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              width="100%"
              position="relative"
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {props.photoOne && (
                <Box mt={2}>
                  <img
                    src={props.photoOne}
                    alt="Additional Photo 1"
                    style={{
                      maxWidth: "600px",
                      maxHeight: "450px",
                      width: "auto",
                      height: "auto",
                      objectFit: "contain"
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box
              bg="blue.50"
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              width="100%"
              position="relative"
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {props.photoTwo && (
                <Box mt={2}>
                  <img
                    src={props.photoTwo}
                    alt="Additional Photo 2"
                    style={{
                      maxWidth: "600px",
                      maxHeight: "450px",
                      width: "auto",
                      height: "auto",
                      objectFit: "contain"
                    }}
                  />
                </Box>
              )}
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}