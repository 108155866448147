import {
  Box,
  Spinner,
  BackgroundProps,
  Text,
  VStack
} from "@chakra-ui/react";

import { UpperThird } from "./UpperThird";

interface LoadingPageProps {bg?: BackgroundProps["bg"]; text?: string; subtext?: string}

const UnstyledLoadingPage: React.FC<LoadingPageProps> = ({
  bg, text, subtext
}) => (
  <Box
    w="100%"
    h="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flex="1"
    bg={bg}
  >
    <VStack
      spacing={4}
      align="center"
    >
      {text && (
        <Text
          textAlign="center"
          fontSize="md"
        >
          {text}
        </Text>
      )}

      {subtext && (
        <Text
          textColor="gray.600"
          textAlign="center"
        >
          {subtext}
        </Text>
      )}

      <Spinner size="md" />
    </VStack>
  </Box>
);

export const LoadingPage = UnstyledLoadingPage;