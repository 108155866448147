import { useState } from "react";
import {
  Box,
  Heading,
  VStack,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  Image,
  Textarea,
  Text
} from "@chakra-ui/react";

import type {
  Response,
  PVInstallerDetails as IPVInstallerDetails,
  ExistingEquipmentDetails as IExistingEquipmentDetails,
  NewEquipmentDetails as INewEquipmentDetails,
  ElectronicsDetails as IElectronicsDetails,
  CablingDetails as ICablingDetails,
  LocationConfirmationDetails as ILocationConfirmationDetails
} from "./types";

// Define common styles
const sectionStyle = {
  borderWidth: "0",
  p: 2,
  mb: 2,
  bg: "white"
};

const inputStyle = {
  borderWidth: "0",
  fontSize: "1rem",
  p: 2,
  minH: "1.8rem",
  h: "1.8rem",
  _focus: { bg: "gray.50" }
};

const tableStyle = {
  borderWidth: "0",
  overflow: "hidden",
  sx: {
    "td, th": {
      padding: "0.5rem !important",
      fontSize: "1rem !important",
      borderWidth: "0 !important"
    },
    "th": {
      fontWeight: "600 !important",
      color: "gray.700 !important",
      bg: "gray.50 !important"
    }
  }
};

// PV Installer Details Component
const PVInstallerSection = ({ pvInstaller }: { pvInstaller?: IPVInstallerDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      PV Installer Details
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr bg="gray.100">
          <Th>PV installer</Th>

          <Th>Email</Th>

          <Th>Telephone</Th>

          <Th>Contact</Th>
        </Tr>
      </Thead>

      <Tbody>
        <Tr>
          <Td>
            <Input
              value={pvInstaller?.name}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              type="email"
              value={pvInstaller?.email}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              type="tel"
              value={pvInstaller?.telephone}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              value={pvInstaller?.contact}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Existing Equipment Component
const ExistingEquipmentSection = ({ equipment }: { equipment?: IExistingEquipmentDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Existing equipment (if present)
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr bg="gray.100">
          <Th>Equipment Type</Th>

          <Th>PV panels</Th>

          <Th>Inverter</Th>

          <Th>Battery</Th>

          <Th>Cabling</Th>

          <Th>Solar thermal</Th>

          <Th>Other</Th>
        </Tr>
      </Thead>

      <Tbody>
        <Tr>
          <Td fontWeight="medium"># present?</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.pvPanelsPresent}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inverterPresent}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.batteryPresent}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.cablingPresent}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.solarThermalPresent}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.otherEquipmentPresent}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium"># to be removed?</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.pvPanelsToRemove}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inverterToRemove}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.batteryToRemove}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.cablingToRemove}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.solarThermalToRemove}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.otherEquipmentToRemove}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Notes (incl who to remove)</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.pvPanelsRemovalNotes}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inverterRemovalNotes}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.batteryRemovalNotes}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.cablingRemovalNotes}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.solarThermalRemovalNotes}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.otherEquipmentRemovalNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// New Equipment Component
const NewEquipmentSection = ({ equipment }: { equipment?: INewEquipmentDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      New equipment
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Tbody>
        <Tr>
          <Td fontWeight="medium">Roof material and mounting type</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.roofMaterialAndMountingType1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.roofMaterialAndMountingType2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.roofMaterialAndMountingType3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.roofMaterialAndMountingTypeNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Fixings to be used</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.fixingsToBeUsed1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.fixingsToBeUsed2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.fixingsToBeUsed3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.fixingsToBeUsedNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Number of panels</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.numPanels1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.numPanels2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.numPanels3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.numPanelsNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Panels to be used</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.panelsToBeUsed1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.panelsToBeUsed2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.panelsToBeUsed3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.panelsToBeUsedNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Orientation - nearest 5 degrees from South (- East, +)</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.orientation1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.orientation2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.orientation3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.orientationNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Inclination of the system - nearest degree from horizontal</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inclination1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inclination2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inclination3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.inclinationNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Shading</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.shading1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.shading2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.shading3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.shadingNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Roof needs separate wiring/inverter?</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.separateWiring1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.separateWiring2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.separateWiring3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.separateWiringNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Scaffolding storeys</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingStoreys1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingStoreys2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingStoreys3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingStoreysNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Scaffolding obstructions</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingObstructions1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingObstructions2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingObstructions3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingObstructionsNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Who is providing scaffolding?</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingProvider1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingProvider2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingProvider3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.scaffoldingProviderNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Existing access to roof</Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.existingAccessToRoof1}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.existingAccessToRoof2}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.existingAccessToRoof3}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={equipment?.existingAccessToRoofNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Electronics Component
const ElectronicsSection = ({ details }: { details?: IElectronicsDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Electronics/others
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr bg="gray.100">
          <Th>Component</Th>

          <Th>Number</Th>

          <Th>Specification</Th>

          <Th>Location/notes</Th>
        </Tr>
      </Thead>

      <Tbody>
        {[
          {
            id: "inverter1",
            label: "Inverter #1",
            number: details?.inverter1Number,
            spec: details?.inverter1Spec,
            location: details?.inverter1Location
          },
          {
            id: "inverter2",
            label: "Inverter #2",
            number: details?.inverter2Number,
            spec: details?.inverter2Spec,
            location: details?.inverter2Location
          },
          {
            id: "inverter3",
            label: "Inverter #3",
            number: details?.inverter3Number,
            spec: details?.inverter3Spec,
            location: details?.inverter3Location
          },
          {
            id: "optimisers",
            label: "Optimisers",
            number: details?.optimisersNumber,
            spec: details?.optimisersSpec,
            location: details?.optimisersLocation
          },
          {
            id: "evCharger",
            label: "EV charger",
            number: details?.evChargerNumber,
            spec: details?.evChargerSpec,
            location: details?.evChargerLocation
          },
          {
            id: "hotWaterDiverter",
            label: "Hot water diverter",
            number: details?.hotWaterDiverterNumber,
            spec: details?.hotWaterDiverterSpec,
            location: details?.hotWaterDiverterLocation
          },
          {
            id: "battery",
            label: "Battery",
            number: details?.batteryNumber,
            spec: details?.batterySpec,
            location: details?.batteryLocation
          },
          {
            id: "gridBackup",
            label: "Grid backup",
            number: details?.gridBackupNumber,
            spec: details?.gridBackupSpec,
            location: details?.gridBackupLocation
          },
          {
            id: "tpLink",
            label: "TP Link",
            number: details?.tpLinkStatus,
            spec: undefined,
            location: details?.tpLinkLocation
          },
          {
            id: "batteryCanopy",
            label: "Battery canopy",
            number: details?.batteryCanopyNumber,
            spec: undefined,
            location: details?.batteryCanopyLocation
          },
          {
            id: "birdProtection",
            label: "Bird protection",
            number: details?.birdProtectionNumber,
            spec: undefined,
            location: details?.birdProtectionLocation
          },
          {
            id: "structuralEngineer",
            label: "Structural engineer",
            number: details?.structuralEngineerStatus,
            spec: undefined,
            location: details?.structuralEngineerLocation
          }
        ].map(item => (
          <Tr key={item.id}>
            <Td>{item.label}</Td>

            <Td>
              <Input
                size="sm"
                value={item.number}
                {...inputStyle}
              />
            </Td>

            <Td>
              {item.spec !== undefined && (
                <Input
                  size="sm"
                  value={item.spec}
                  {...inputStyle}
                />
              )}
            </Td>

            <Td>
              <Input
                size="sm"
                value={item.location}
                {...inputStyle}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);

// Cabling Details Component
const CablingSection = ({ details }: { details?: ICablingDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Cabling Details
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr bg="gray.100">
          <Th>Cabling</Th>

          <Th>Internal/external?</Th>

          <Th>Distance</Th>

          <Th>Size/diameter cable</Th>

          <Th>Notes</Th>
        </Tr>
      </Thead>

      <Tbody>
        {[
          "pvAC",
          "pvDC",
          "ev"
        ].map(cableType => {
          type CableKeys = {
            type: keyof ICablingDetails;
            distance: keyof ICablingDetails;
            size: keyof ICablingDetails;
            notes: keyof ICablingDetails;
          };

          const keys: CableKeys = {
            type: `wiring${cableType}Type` as keyof ICablingDetails,
            distance: `wiring${cableType}Distance` as keyof ICablingDetails,
            size: `wiring${cableType}Size` as keyof ICablingDetails,
            notes: `wiring${cableType}Notes` as keyof ICablingDetails
          };

          const cableDetails = {
            type: details?.[ keys.type ],
            distance: details?.[ keys.distance ],
            size: details?.[ keys.size ],
            notes: details?.[ keys.notes ]
          };

          return (
            <Tr key={cableType}>
              <Td>{`Wiring ${cableType.toUpperCase()}`}</Td>

              <Td>
                <Input
                  size="sm"
                  value={cableDetails.type}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={cableDetails.distance}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={cableDetails.size}
                  {...inputStyle}
                />
              </Td>

              <Td>
                <Input
                  size="sm"
                  value={cableDetails.notes}
                  {...inputStyle}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  </Box>
);

// Location Confirmations Component
const LocationConfirmationsSection = ({ details }: { details?: ILocationConfirmationDetails }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Location Confirmations
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Thead>
        <Tr bg="gray.100">
          <Th>Confirmation</Th>

          <Th>Panels</Th>

          <Th>Inverter(s)</Th>

          <Th>Battery/other electronics</Th>

          <Th>EV charger</Th>

          <Th>Key cable runs</Th>

          <Th>Notes</Th>
        </Tr>
      </Thead>

      <Tbody>
        <Tr>
          <Td>Confirm locations agreed with homeowner</Td>

          <Td>
            <Checkbox isChecked={details?.panelLocationsConfirmed} />
          </Td>

          <Td>
            <Checkbox isChecked={details?.inverterLocationsConfirmed} />
          </Td>

          <Td>
            <Checkbox isChecked={details?.batteryElectronicsLocationsConfirmed} />
          </Td>

          <Td>
            <Checkbox isChecked={details?.evChargerLocationConfirmed} />
          </Td>

          <Td>
            <Checkbox isChecked={details?.cableRunLocationsConfirmed} />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.additionalLocationNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Array Details Component
const ArrayDetailsSection = ({ details }: { details?: Response["arrayDetails"] }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Array Details
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Tbody>
        <Tr>
          <Td fontWeight="medium">Rafter depth (mm)</Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterDepth?.[ 0 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterDepth?.[ 1 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterDepth?.[ 2 ]}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Rafter width (mm)</Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterWidth?.[ 0 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterWidth?.[ 1 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterWidth?.[ 2 ]}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Span between rafters (mm)</Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterSpan?.[ 0 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterSpan?.[ 1 ]}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={details?.rafterSpan?.[ 2 ]}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Confirm roof can take weight</Td>

          <Td>
            <Checkbox
              isChecked={details?.roofCanTakeWeight?.[ 0 ]}
            />
          </Td>

          <Td>
            <Checkbox
              isChecked={details?.roofCanTakeWeight?.[ 1 ]}
            />
          </Td>

          <Td>
            <Checkbox
              isChecked={details?.roofCanTakeWeight?.[ 2 ]}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Confirm shading/sunpath diagram supplied</Td>

          <Td>
            <Checkbox
              isChecked={details?.shadingDiagramSupplied?.[ 0 ]}
            />
          </Td>

          <Td>
            <Checkbox
              isChecked={details?.shadingDiagramSupplied?.[ 1 ]}
            />
          </Td>

          <Td>
            <Checkbox
              isChecked={details?.shadingDiagramSupplied?.[ 2 ]}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Property Status Component
const PropertyStatusSection = ({ status }: { status?: Response["propertyStatus"] }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Property Status
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Tbody>
        <Tr>
          <Td fontWeight="medium">Are roofs in good state of repair?</Td>

          <Td>
            <Input
              size="sm"
              value={status?.roofRepairStatus}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={status?.roofRepairNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td fontWeight="medium">Are electrics in good state of repair?</Td>

          <Td>
            <Input
              size="sm"
              value={status?.electricsRepairStatus}
              {...inputStyle}
            />
          </Td>

          <Td>
            <Input
              size="sm"
              value={status?.electricsRepairNotes}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Panel Layout Component
const PanelLayoutSection = ({ panelLayoutImg, panelLayoutNotes }: { panelLayoutImg?: string; panelLayoutNotes?: string }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Panel Layout
    </Heading>

    <VStack
      spacing={4}
      align="stretch"
    >
      <Box
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="md"
        p={4}
        bg="blue.50"
        minHeight="300px"
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {panelLayoutImg ? (
          <Image
            src={panelLayoutImg}
            alt="Panel Layout"
            maxHeight="450px"
            maxWidth="600px"
            width="auto"
            height="auto"
            objectFit="contain"
          />
        ) : (
          <Text color="gray.500">Panel layout jpg</Text>
        )}
      </Box>

      <Input
        placeholder="Notes"
        value={panelLayoutNotes}
        {...inputStyle}
      />
    </VStack>
  </Box>
);

// Job Complexity Component
const JobComplexitySection = ({ pvComplexity, evComplexity }: { pvComplexity?: string; evComplexity?: string }) => (
  <Box {...sectionStyle}>
    <Heading
      as="h3"
      size="md"
      mb={2}
    >
      Job Complexity
    </Heading>

    <Table
      variant="simple"
      size="sm"
      {...tableStyle}
    >
      <Tbody>
        <Tr>
          <Td width="200px">PV Job complexity</Td>

          <Td>
            <Input
              size="sm"
              value={pvComplexity}
              {...inputStyle}
            />
          </Td>
        </Tr>

        <Tr>
          <Td>EV job complexity</Td>

          <Td>
            <Input
              size="sm"
              value={evComplexity}
              {...inputStyle}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

// Main Component
export default function SolarDriveway(props: Response) {
  return (
    <Box
      as="section"
      mb={4}
      p={2}
      bg="gray.50"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="lg"
      boxShadow="sm"
      sx={{ "@media print": { boxShadow: "none" } }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Heading
          as="h2"
          size="lg"
        >
          Solar+ Driveway
        </Heading>
      </Box>

      <VStack
        spacing={2}
        align="stretch"
      >
        <JobComplexitySection
          pvComplexity={props.pvJobComplexity}
          evComplexity={props.evJobComplexity}
        />

        <PVInstallerSection pvInstaller={props.pvInstaller} />

        <ExistingEquipmentSection equipment={props.existingEquipment} />

        <NewEquipmentSection equipment={props.newEquipment} />

        <ArrayDetailsSection details={props.arrayDetails} />

        <PropertyStatusSection status={props.propertyStatus} />

        <PanelLayoutSection
          panelLayoutImg={props.panelLayoutImg}
          panelLayoutNotes={props.panelLayoutNotes}
        />

        <ElectronicsSection details={props.electronics} />

        <CablingSection details={props.cabling} />

        <LocationConfirmationsSection details={props.locationConfirmations} />
      </VStack>
    </Box>
  );
}
