import {
  Box,
  Heading,
  SimpleGrid,
  Checkbox,
  Text
} from "@chakra-ui/react";

import { Response } from "./types";

// Define common styles
const sectionStyle = {
  borderWidth: "1px",
  borderColor: "gray.300",
  borderRadius: "md",
  p: 2,
  mb: 3,
  bg: "white"
};

interface RetrofitItem {
  label: string;
  name: string;
}

export function RetrofitScope({
  cavityWallInsulation,
  externalWallInsulation,
  internalWallInsulation,
  pv,
  floorInsulation,
  lowEnergyLighting,
  loftInsulation,
  battery,
  roofRoomInsulation,
  evCharger,
  flatRoofInsulation,
  smartCylinder,
  stopHeatingSystem2,
  unheatedSpaceInsulation,
  stopSecondaryHeating,
  newBoiler,
  cylinderSize,
  ashp,
  heatingSystem1,
  controlsUpgrade,
  heatingSystem2,
  glazingDoors,
  secondaryHeatingSystem,
  underfloorHeating,
  hotWaterSystem,
  draughtproofing,
  powerSupply,
  pvHotWaterDiverter,
  mainFuseSize
}: Response) {
  const retrofitItems: RetrofitItem[] = [
    {
      label: "Cavity wall insulation",
      name: "cavityWallInsulation"
    },
    {
      label: "External wall insulation",
      name: "externalWallInsulation"
    },
    {
      label: "Internal wall insulation",
      name: "internalWallInsulation"
    },
    {
      label: "PV",
      name: "pv"
    },
    {
      label: "Floor insulation",
      name: "floorInsulation"
    },
    {
      label: "Low energy Lighting",
      name: "lowEnergyLighting"
    },
    {
      label: "Loft insulation",
      name: "loftInsulation"
    },
    {
      label: "Battery",
      name: "battery"
    },
    {
      label: "Roof room insulation",
      name: "roofRoomInsulation"
    },
    {
      label: "EV charger",
      name: "evCharger"
    },
    {
      label: "Flat roof insulation",
      name: "flatRoofInsulation"
    },
    {
      label: "Smart cylinder",
      name: "smartCylinder"
    },
    {
      label: "Stop heating system 2",
      name: "stopHeatingSystem2"
    },
    {
      label: "Unheated space insulation",
      name: "unheatedSpaceInsulation"
    },
    {
      label: "Stop secondary heating",
      name: "stopSecondaryHeating"
    },
    {
      label: "New boiler",
      name: "newBoiler"
    },
    {
      label: "Cylinder size",
      name: "cylinderSize"
    },
    {
      label: "ASHP",
      name: "ashp"
    },
    {
      label: "Heating system 1",
      name: "heatingSystem1"
    },
    {
      label: "Controls upgrade",
      name: "controlsUpgrade"
    },
    {
      label: "Heating system 2",
      name: "heatingSystem2"
    },
    {
      label: "Glazing/doors",
      name: "glazingDoors"
    },
    {
      label: "Secondary heating system",
      name: "secondaryHeatingSystem"
    },
    {
      label: "Underfloor heating",
      name: "underfloorHeating"
    },
    {
      label: "Hot water system",
      name: "hotWaterSystem"
    },
    {
      label: "Draughtproofing",
      name: "draughtproofing"
    },
    {
      label: "Power supply",
      name: "powerSupply"
    },
    {
      label: "PV hot water diverter",
      name: "pvHotWaterDiverter"
    },
    {
      label: "Main fuse size",
      name: "mainFuseSize"
    }
  ];

  const getCheckboxState = (name: string): boolean => {
    const values = {
      cavityWallInsulation,
      externalWallInsulation,
      internalWallInsulation,
      pv,
      floorInsulation,
      lowEnergyLighting,
      loftInsulation,
      battery,
      roofRoomInsulation,
      evCharger,
      flatRoofInsulation,
      smartCylinder,
      stopHeatingSystem2,
      unheatedSpaceInsulation,
      stopSecondaryHeating,
      newBoiler,
      cylinderSize,
      ashp,
      heatingSystem1,
      controlsUpgrade,
      heatingSystem2,
      glazingDoors,
      secondaryHeatingSystem,
      underfloorHeating,
      hotWaterSystem,
      draughtproofing,
      powerSupply,
      pvHotWaterDiverter,
      mainFuseSize
    };

    return Boolean(values[ name as keyof typeof values ]);
  };

  return (
    <Box
      as="section"
      mb={8}
      p={4}
      bg="gray.50"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="lg"
      boxShadow="sm"
      sx={{ "@media print": { boxShadow: "none" } }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Heading
          as="h2"
          size="lg"
        >
          Retrofit Scope
        </Heading>
      </Box>

      <Box {...sectionStyle}>
        <SimpleGrid
          columns={2}
          spacing={2}
        >
          {retrofitItems
            .filter((item: RetrofitItem) => getCheckboxState(item.name))
            .map(item => (
              <Box
                key={item.name}
                display="flex"
                alignItems="center"
                p={2}
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="md"
              >
                <Checkbox
                  name={item.name}
                  isChecked
                  colorScheme="blue"
                />

                <Text ml={2}>
                  {item.label}
                </Text>
              </Box>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
